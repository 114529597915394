<template>
  <base-page-layout title="Contatos Whatsapp">
    <v-row no-gutters justify="end">
      <v-col cols="auto">
        <mf-button color="primary" class="mr-2" label="Importar .CSV<" outlined @click="csvDialog = true"></mf-button>
      </v-col>
      <v-col class="ml-2" cols="auto">
        <mf-button color="primary" class="mr-2" label="Deletar .CSV" outlined @click="deleteCsvDialog = true"></mf-button>
      </v-col>
      <v-col class="ml-2" cols="auto">
        <mf-button color="primary" label="Painel de Ações" @click="actionsPanel"></mf-button>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <mf-card-container
          v-model="search"
          placeholder="Busca por telefone"
          use-search
          title="Contatos"
          :description="'Listagem dos contatos que estão vinculados a conta ' + name"
        >
          <data-table-selectable
            :search="search"
            :headers="headers"
            :query="query"
            queryName="adminWppUsers"
            :account-id="accountId"
            :query-params="queryParams"
            :selected.sync="numbers"
            :itemKey="itemKey"
          >
            <template v-slot:[`item.number`]="{ item }">
              {{ formatNumber(item.number) }}
            </template>

            <template v-slot:[`item.created_at`]="{ item }">{{ item.created_at | date }}</template>

            <template v-slot:[`item.updated_at`]="{ item }">{{ item.updated_at | date }}</template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-menu :close-on-content-click="false">
                <template #activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="openContactModal(item.number, item._id)">
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-switch v-model="item.active" @change="changeActive($event, item._id)" />
                </v-layout>
              </v-flex>
            </template>
          </data-table-selectable>
        </mf-card-container>
      </v-col>
    </v-row>

    <!-- modal -->
    <v-dialog v-model="openModal" persistent max-width="300">
      <v-card class="pa-2">
        <v-card-title class="headline justify-center"><v-icon class="mr-2">mdi-account</v-icon> Contato</v-card-title>
        <v-card-text class="text-center"><strong>Número</strong>: {{ formatNumber(selected.number) }}</v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" dark @click="closeModal">
            Fechar
          </v-btn>
          <v-btn color="error" dark @click="deleteNumber(selected.id)"> <v-icon>mdi-alert</v-icon> Excluir Contato </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- actions dialog -->
    <wpp-actions v-model="actionsDialog" :selected.sync="numbers" :accountId="accountId" @close="closeActionsPanel" />
    <import-csv v-model="csvDialog" :account-id="accountId" @close="closeImportCsv" />
    <delete-csv v-model="deleteCsvDialog" :account-id="accountId" @close="closeDeleteCsv" />
  </base-page-layout>
</template>
<script>
import { QUERY_GET_WPP_USERS, MUTATION_ACTIVE_WAPP_NUMBER, MUTATION_DELETE_WAPP_NUMBER, QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    DataTableSelectable: () => import('@/components/tables/DataTableSelectable'),
    WppActions: () => import('./WppActions'),
    ImportCsv: () => import('./ImportCsv'),
    DeleteCsv: () => import('./DeleteCsv')
  },
  data: () => ({
    accountId: '',
    search: '',
    openModal: false,
    selected: {
      id: '',
      number: ''
    },
    numbers: [],
    actionsDialog: false,
    deleteCsvDialog: false,
    csvDialog: false,
    name: ''
  }),
  computed: {
    query() {
      return QUERY_GET_WPP_USERS
    },
    queryParams() {
      return { id: this.accountId }
    },
    itemKey() {
      return 'number'
    },
    headers() {
      return [
        {
          text: 'Número',
          value: 'number',
          width: '10%',
          align: 'center'
        },
        {
          text: 'Criado em',
          value: 'created_at',
          width: '30%',
          align: 'center'
        },
        {
          text: 'Atualizado em',
          value: 'updated_at',
          width: '20%',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'active',
          width: '15%',
          align: 'center'
        },
        {
          text: '',
          value: 'delete',
          width: '15%',
          align: 'center'
        }
      ]
    }
  },
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.$route.params.id }
      },
      result({ data }) {
        this.name = data.adminAccount.name
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    async changeActive(e, id) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_ACTIVE_WAPP_NUMBER,
          variables: {
            id: this.accountId,
            contact_id: id,
            active: e
          }
        })
        this.$alert({
          alert_message: `Número ${e === true ? 'ativado' : 'inativado'}`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: `Falha ao ${e === true ? 'ativar' : 'inativar'} número`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async deleteNumber(id) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_WAPP_NUMBER,
          variables: {
            id: this.accountId,
            contact_id: id
          }
        })
        this.closeModal()
        this.$alert({
          alert_message: 'Número deletado com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.go()
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Falha ao deletar número',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async deleteSelectedNumbers() {
      console.log(this.selectedNumbers)
    },
    formatNumber(n) {
      const countryCode = n.slice(0, 2)
      const ddd = n.slice(2, 4)
      const phone = n.slice(4, n.length)
      return `+${countryCode} (${ddd}) ${phone}`
    },
    openContactModal(n, id) {
      this.selected.number = n
      this.selected.id = id
      this.openModal = true
    },
    closeModal() {
      this.selected.id = ''
      this.selected.number = ''
      this.openModal = false
    },
    actionsPanel() {
      this.actionsDialog = true
    },
    closeActionsPanel(refresh) {
      this.actionsDialog = false
      this.numbers.splice(0, this.numbers.length)
      if (refresh) this.$router.go()
    },
    closeImportCsv() {
      this.csvDialog = false
    },
    closeDeleteCsv() {
      this.deleteCsvDialog = false
    }
  }
}
</script>
